import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import { Grid, Nav, SanitySlice, SEO, Sidebar } from "~components";
import { useSanitySlices } from "~hooks";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * Static page routes @ /*.
 */
const CustomPage = ({ data: staticData }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { sanityCustomPage } = staticData;
  const { pagebuilder } = sanityCustomPage;

  const { getSlicesByName, slices } = useSanitySlices(
    pagebuilder?.slices || []
  );

  const [atfSlices, setATFSlices] = useState([]);
  const [defaultSlices, setDefaultSlices] = useState(slices);
  const [loaded, setLoaded] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (loaded || atfSlices?.[0] || !slices?.[0]) {
      return;
    }

    setLoaded(true);

    const atf = getSlicesByName([`Hero`, `LgfAnimatedHero`]).filter(
      ({ aboveContent }) => aboveContent
    );

    if (!atf?.[0]) {
      return;
    }

    setATFSlices(atf);
    setDefaultSlices(slices.filter(({ aboveContent }) => !aboveContent));
  }, [atfSlices, loaded, slices]);

  // ---------------------------------------------------------------------------
  // render

  let isPreview = false;

  if (typeof window !== `undefined`) {
    isPreview = window.location.host.split(`-`)[0] === `preview`;
  }

  return (
    <>
      <SEO pageTitle={sanityCustomPage.title} />

      {atfSlices?.[0] &&
        atfSlices.map((slice) => (
          <SanitySlice key={slice._key} className="slice-atf" data={slice} />
        ))}

      <div className={styles.wrapper}>
        <Grid>
          {!isPreview && (
            <div className={cn(styles.sidebar)}>
              <Sidebar />
            </div>
          )}

          <div className={cn(styles.content, { [styles.preview]: isPreview })}>
            {defaultSlices?.[0] &&
              defaultSlices.map((slice) => (
                <SanitySlice key={slice._key} data={slice} />
              ))}

            <Nav />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default CustomPage;

export const query = graphql`
  query CustomPage($id: String!) {
    sanityCustomPage(id: { eq: $id }) {
      title
      slug {
        current
      }

      #
      # sync this to slices available both locally and in lam-dbp-slices

      pagebuilder {
        slices {
          # custom slices
          ...LgfAnimatedHeroFragment
          ...LgfMenuListFragment

          # package slices
          ...HeroFragment
          ...ColorGridFragment
          ...ImageGridFragment
          ...LinkGridFragment
          ...SimpleImageFragment
          ...SimpleVideoFragment
          ...TextArticleFragment
          ...TypeTableFragment
        }
      }
    }
  }
`;
